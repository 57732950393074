import { useState } from "react";
import logo from "../../assets/Logo-pri-licores.png";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {ENDPOINT} from "../../constants/endpointConstants"
import { Modal } from "react-bootstrap";
import { BUSINESS_CODE } from "../../constants/sharedConstans";


const LoginPage = ({bringUser}) => {
    const[user, setUser] = useState('');
    const[password, setPassword] = useState('');
    const[loginError, setLoginError] = useState(false);
    const[loading, setLoading] = useState(false);
    const [businessCode, setBusinessCode] = useState(BUSINESS_CODE.LIQUOR);

    const navigate = useNavigate ();

    const userLogin = async () => {
        let addUser ={
            userId:user,
            password: password,
        }
        setLoading(true); 
        try{
            let submitUser = await axios.post(ENDPOINT.POST_LOGIN,addUser); 
            const {token, name, userId} = submitUser.data;
            setLoading(false)
            localStorage.setItem('businessCode', businessCode)
            localStorage.setItem('prilicores-user', JSON.stringify({
                token,
                name,
                userId
            }))
            bringUser(name, businessCode)
            navigate("caja")
        } catch(error){
            setLoginError(true)
            setLoading(false)
        }
    } 

    const userChange = (event) => {
        setUser(event.target.value);
    }

    const userPassword = (event) => {
        setPassword(event.target.value);
    }

    const businesCodeChange = (event) => {
        setBusinessCode(event.target.value);
    }

    const closeModal = () => {
        setLoginError(false)
    }

    const buttonContent = () => {
        if(loading === false){
            return "Ingresar";
        } else {
            return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        }
    }

    const isButtonDisabled = () => {
        if(user === "" || password === ""){
            return true
        } else {
            return false
        }
    }

    return (
        <div>
            <div className="row justify-content-center">
                <div className='login col-10 col-md-4'>
                    <div className="row justify-content-center">
                        <img src={logo}/>
                    </div>
                    <div className="row justify-content-center">
                        <div className=" col-8 mb-3">
                            <input type="text" placeholder="Usuario" className="form-control" onChange={userChange}/>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-8 mb-3">
                            <input type="password" placeholder="Contraseña" className="form-control" onChange={userPassword}/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-evenly mb-5">
                        <div className="d-flex flex-column">
                            <label htmlFor="option-1" className={(businessCode === BUSINESS_CODE.LIQUOR ? 'active ' : '') + "d-flex flex-column align-items-center"}>
                                <img className="business-option" src={logo}/>
                                <p>Licorera</p>
                            </label>
                            <input type="radio" id="option-1" name="radio-group" value={BUSINESS_CODE.LIQUOR} checked={BUSINESS_CODE.LIQUOR === businessCode} onChange={businesCodeChange}/>
                        </div>
                        <div className="d-flex flex-column">
                            <label htmlFor="option-2" className={(businessCode === BUSINESS_CODE.BAR ? 'active ' : '') + "d-flex flex-column align-items-center"}>
                                <img className="business-option bar" src={logo}/>
                                <p>Bar</p>
                                </label>
                            <input type="radio" id="option-2" name="radio-group" value={BUSINESS_CODE.BAR} checked={BUSINESS_CODE.BAR === businessCode} onChange={businesCodeChange}/>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" className="btn btn-primary btn-sm loader" onClick={userLogin} disabled={isButtonDisabled()}>
                            {buttonContent()}
                        </button>
                    </div>
                </div> 
            </div>
            <Modal show={loginError} onHide={closeModal}>
                <Modal.Header closeButton className="style-modal-header">
                    <Modal.Title >Error de ingreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>Usuario o contraseña no encontrados</Modal.Body>
            </Modal>
            
        </div>
        
        )
}

export default LoginPage;