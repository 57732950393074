export const getUserData = () => {
    const user = localStorage.getItem("prilicores-user");
    return JSON.parse(user);
}

export const getTokenAndBusinessCode = () => {
    const token = getUserData().token;
    const businessCode = localStorage.getItem('businessCode');
    const extraOptions = {
        headers: {
            authorization: `Bearer ${token}`,
            business: businessCode
        }
    }
    return extraOptions
}

export const getRangeDates = (start, end) => {
    return [
        new Date(`${start.getMonth() + 1}/${start.getDate()}/${start.getFullYear()} 00:00:00`),
        new Date(`${end.getMonth() + 1}/${end.getDate()}/${end.getFullYear()} 23:59:59`)
    ]
}